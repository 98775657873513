




























































































































































































.p-reset {
  > .inner {
    width: 1100px;
    margin: 0 auto;
    > .steps {
      height: 42px;
      text-align: center;
      margin: 20px 0px 100px 0px;
      border-bottom: 2px solid #dddddd;
      > .step {
        width: 180px;
        padding: 10px;
        font-size: 18px;
        line-height: 20px;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        border-bottom: 2px solid transparent;
        > .num {
          width: 20px;
          height: 20px;
          font-size: 18px;
          color: #ffffff;
          margin-right: 8px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          background-color: #c2c2c2;
        }
        &.active {
          border-color: #41ac76;
          > .num {
            background-color: #41ac76;
          }
        }
      }
    }
    > .content {
      margin: 0px 0px 40px 0px;
      > .form {
        width: 400px;
        margin: 0px auto;
        > .item {
          display: flex;
          margin: 15px 0px;
          line-height: 24px;
          align-items: flex-start;
          > label {
            width: 80px;
            margin: 3px 10px;
            text-align: right;
            display: inline-block;
          }
          > .content {
            flex: 1;
            width: 0px;
            > .v-radio {
              line-height: 30px;
            }
            > .v-button {
              width: 264px;
            }
            > .v-input {
              > button {
                width: 97px;
                border: none;
                outline: none;
                cursor: pointer;
                color: #ffffff;
                padding: 0px 15px;
                background-color: #41ac76;
                box-shadow: 0 0 0 1px #41ac76;
                &[disabled] {
                  cursor: no-drop;
                  background-color: #c4c4c4;
                  box-shadow: 0 0 0 1px #c4c4c4;
                }
              }
            }
          }
        }
      }
      > .done {
        margin: 100px auto;
        text-align: center;
        > .tip {
          color: #41ac76;
          line-height: 30px;
          margin-bottom: 15px;
          > i.icon-success {
            margin: 10px;
            font-size: 36px;
            vertical-align: middle;
          }
          > span {
            font-size: 18px;
            vertical-align: middle;
          }
        }
        > .countdown {
          color: #999999;
          margin-left: 46px;
        }
      }
    }
  }
}
